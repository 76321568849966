<template>
  <div>
    <topbar drawer :title="navbarTitle"></topbar>
    <v-container v-if="!loading">
      <v-card>
        <v-data-table
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :headers="headers"
          :items="users"
          :search="search"
        >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4 pt-6"
          ></v-text-field>
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          <div style="background-color: red">{{ item }}</div>
        </template>
        <template v-slot:[`item.userId`]="{ item }">
          <div>{{ item.userId ? 'Ja' : 'Nein' }}</div>
        </template>
        <template v-slot:[`item.discordId`]="{ item }">
          <div>{{ item.discordId ? 'Ja' : 'Nein' }}</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn small class="mr-2" @click="viewHangar(item.userId || '')">
            <v-icon small left>fa-bookmark</v-icon>
            Hangar
          </v-btn>
          <v-btn small @click="viewUser(item.handle)">
            <v-icon small left>fa-eye</v-icon>
            View User
          </v-btn>
        </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import Topbar from '../components/Topbar';
  import {getInstance} from '../auth';


  export default {
    name: 'Members',

    components: {
      Topbar
    },
    data() {
      return {
        navbarTitle: 'Members',
        search: '',
        sortBy: 'userId',
        sortDesc: true,
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Rank', value: 'rank' },
          { text: 'Registriert', value: 'userId'},
          { text: 'RSI Handle', value: 'handle' },
          { text: 'Discord name', value: 'discordName'},
          { text: 'Actions', value: 'actions', width: 320, sortable: false }
        ],
      };
    },

    computed: {
      loading() {
        return this.$store.getters.loading;
      },
      user() {
        return this.$store.getters['user/getUser'];
      },
      users() {
        return this.$store.getters['user/getUsers'];
      }
    },

    beforeMount() {
      this.loadItems();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },

    methods: {
      async loadItems() {
        this.$store.dispatch('loading', true, {root: true});
        if (!this.user.userId) {
          await this.$store.dispatch('user/getUser', this.$auth && this.$auth.user.sub);
        }
        const authService = getInstance();
        await this.$store.dispatch('user/getUser', authService.user && authService.user.sub);
        await this.$store.dispatch('user/getUsers');
        this.$store.dispatch('loading', false, {root: true});
      },
      viewHangar(userId) {
        if (!userId) {
          this.$swal({
            title: 'This user is not registered, so he does not have a hangar',
            icon: 'info'
          });
          return;
        }
        this.$store.dispatch('hangar/resetShipFilterStore');
        this.$router.push({name: 'hangar', query: {userId}});
      },
      viewUser(handle) {
        this.$router.push({name: 'user', query: {handle}});
      }
    }
  };
</script>

<style>
</style>
