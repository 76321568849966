var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('topbar',{attrs:{"drawer":"","title":_vm.navbarTitle}}),(!_vm.loading)?_c('v-container',[_c('v-card',[_c('v-data-table',{attrs:{"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"headers":_vm.headers,"items":_vm.users,"search":_vm.search},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4 pt-6",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"background-color":"red"}},[_vm._v(_vm._s(item))])]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.userId ? 'Ja' : 'Nein'))])]}},{key:"item.discordId",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.discordId ? 'Ja' : 'Nein'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewHangar(item.userId || '')}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fa-bookmark")]),_vm._v(" Hangar ")],1),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.viewUser(item.handle)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fa-eye")]),_vm._v(" View User ")],1)]}}],null,true)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }